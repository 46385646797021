import Phaser from "phaser";
import Scaling from "../configs/scaling";

import Player from "../components/player/player";
import Level from "../components/systems/level";
import Balls from "../components/balls/balls";
import Constants from "../configs/constants";
import Bricks from "../components/bricks/bricks";
import sessionSaveFile from "@/utils/game/SessionSaveFile";
import { ScoreDataDefault, ScoreGameTypes } from "@/interfaces/ISessionFile";
import _BaseBall from "../components/balls/types/_baseBall";
import Brick from "../components/bricks/types/_baseBrick";
import eventsController from "../controllers/eventsController";
import Setup from "@/helpers/Setup";
import { millisToMinutesAndSeconds } from "../configs/helpers";
import scoreController from "@/utils/game/ScoreController";

enum LevelTypes {
  RANDOMIZED = 'randomized',
  SELECTED = 'selected'
}

export default class Main extends Phaser.Scene {
  lives!: number;
  score!: number;
  startTime!: Date;
  balls!: Balls;
  player!: Player;
  bricks!: Bricks;
  level!: Level;
  dialogStartGame!: Phaser.GameObjects.Text;
  hasStarted!: boolean;

  constructor() {
    super({ key: "game" });
  }

  init() {
    /** SAVEFILE */
    sessionSaveFile.create({
      type: ScoreGameTypes.INFINITE,
      stats: ['bricks', 'lives', 'playtimeStatistic']
    });

    /** SETTINGS */
    this.startTime = new Date();
    this.lives = Constants.PLAYER_LIVES;
    this.score = 0;
    this.hasStarted = false;

    /** SAVEFILE: Default */
    sessionSaveFile.updateValue('lives', this.lives);

    /** SCENES: HUD */
    this.scene.launch('hud');
  }

  create() {
    /** API: Start level */
    this.game.events.emit('start');

    /** CREATE */
    this.setGameObjects();
    this.setDialogs();
    this.setControllers();
    this.setCamera();
    this.setEvents();
    this.setPhysics();
    this.setAudio();

    /** GAME: Start */
    this.start();
  }

  setGameObjects() {
    /** WORLD: Balls */
    this.balls = new Balls(this, 10);

    /** WORLD: Player */
    this.player = new Player(this, this.cameras.main.centerX, this.cameras.main.height - Scaling.getPixelbyDPR(45));

    /** WORLD: Bricks */
    this.bricks = new Bricks(this);
  }
  
  setDialogs(){
    /** DIALOG: Start game message */
    this.dialogStartGame = this.add.text(this.cameras.main.centerX, this.player.getBounds().top - Scaling.getPixelbyDPR(52), Setup.getCopy('general.touchStart'), {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(18)}px`,
      color: '#FFFFFF',
      align: 'center'
    }).setOrigin(0.5, 1);      
  }

  setControllers() {
    /** Level: Get level based upon type */
    const type = Setup.getValue('settings.game.levels.type') as LevelTypes;
    let level: number;

    switch (type) {
      case LevelTypes.SELECTED:
        level = parseInt(Setup.getValue('settings.game.levels.activeLevel'));
        break;
      case LevelTypes.RANDOMIZED:
        const levelsJson = JSON.parse(Setup.getValue('settings.game.levels.json').value);
        const levelsAmount = levelsJson.levels.length;

        level = Phaser.Math.Between(1, levelsAmount);
        break;
      default:
        level = 1;
        break;
    }

    /** Level: Create level */
    this.level = new Level(this, { key: `level${level}` });
  }

  setCamera(){}

  setEvents() {
    /** EVENT: Game start */
    eventsController.on('game-start', () => {
      if(!this.hasStarted){
        this.hasStarted = true;
        this.dialogStartGame.setActive(false).setVisible(false);
      }
    });

    /** EVENT: Life gain */
    eventsController.on('life-gain', () => {
      this.lives++;
      sessionSaveFile.incrementValue('lives', 1);

      eventsController.emit('hud-update-lives', { amount: this.lives });
    })

    /** EVENT: Life lost */
    eventsController.on('life-lost', () => {
      this.lives--;
      sessionSaveFile.incrementValue('lives', -1);

      eventsController.emit('hud-update-lives', { amount: this.lives });

      if (this.lives > 0) {
        this.player.attachBall();
      } else {
        this.gameOver();
      }
    });

    /** EVENT: Score update */
    eventsController.on('score-update', (data: any) => {
      this.score += data.amount;
      eventsController.emit('hud-update-score', { amount: this.score });
    })

    /** EVENT: Audio play */
    eventsController.on('sound-play', (key: string) => {
      this.sound.play(key);
    })
  }

  setPhysics() {
    /** WORLD: Add collision to world bounds */
    this.physics.world.setBoundsCollision(true, true, true, false);

    /** COLLIDER: Balls <====> Paddle */
    this.physics.add.collider(this.balls, this.player, (ball, paddle) => {
      const _player = paddle as Player;
      const _ball = ball as _BaseBall;

      _player.bounce(_ball);
    });

    /** COLLIDER: Balls <====> Bricks */
    this.physics.add.collider(this.balls, this.bricks, (ball, brick) => {
      const _ball = ball as _BaseBall;
      const _brick = brick as Brick;

      _brick.hit();
      _ball.increaseSpeed();

      if (!this.bricks.hasActive()) {
        this.gameOver();
      }
    });
  }

  setAudio(){
    this.sound.add('bounce', { volume: 0.1 });
    this.sound.add('hit', { volume: 0.1 });
    this.sound.add('launch', { volume: 0.1 });
  }

  start() {
    this.player.attachBall();
    this.bricks.show(() => {
      eventsController.emit('hud-start-stopwatch');
      this.player.setLaunchable(true);
    });
  }

  update(){}

  gameOver() {
    this.shutdown();
  }

  shutdown() {
    /** Eventcontroller: Remove all listeners */
    eventsController.removeAllListeners();

    /** Scene: Stop current running scenes */
    this.scene.stop();
    this.scene.stop('hud');

    /** SCORE: Apply multipliers to score */
    const playtime = new Date().getTime() - this.startTime.getTime();
    const score = Math.floor((parseInt(`${sessionSaveFile.getValue('bricks')}`) * Constants.SCORE_PER_BRICK) + (this.lives * Constants.SCORE_PER_LIFE) - ((playtime / 1000) * Constants.SCORE_PER_SECOND));
    sessionSaveFile.updateValue(ScoreDataDefault.TOTALSCORE, Math.max(0, score));

    /** PROGRESSION: update playtime */
    sessionSaveFile.updateValue(ScoreDataDefault.PLAYTIME, playtime);
    sessionSaveFile.updateValue('playtimeStatistic', millisToMinutesAndSeconds(playtime));

    /** END LEVEL */
    const result = scoreController.getResult();
    console.log(result)
    this.game.events.emit('end', result);
  }
}