import Phaser from "phaser";
import eventsController from "../../controllers/eventsController";
import BallDefault from "./types/default";

export default class Balls extends Phaser.GameObjects.Group {
  constructor(scene: Phaser.Scene, amount: number) {
    super(scene);

    /** Balls: Create a group of re-usable balls */
    for (let index = 0; index < amount; index++) {
      const ball = new BallDefault(this.scene, 0, 0);
      this.add(ball);
    }

    /** Events */
    eventsController.on('balls-spawn', (data: any, callback: (param: any) => void) => { 
      const ball = this.spawn(data.x, data.y, data.isStatic);
      if(callback) callback(ball);
    })

    eventsController.on('ball-death', () => {
      if (!this.hasActiveBall()) eventsController.emit('life-lost');
    })    
  }

  hasActiveBall() {
    return this.getFirstAlive() ? true : false;
  }

  spawn(x: number, y: number, isStatic: boolean) {
    const ball = this.getFirstDead();

    // ball: spawn //
    ball ? ball.revive(x, y, isStatic) : null;

    return ball;
  }
}