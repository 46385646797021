import Phaser from "phaser";
import eventsController from "../../controllers/eventsController";
import BrickDefault from "./types/default";
import BrickPowerupBall from "./types/powerupBall";
import BrickPowerupExplode from "./types/powerupExplode";
import BrickPowerupLife from "./types/powerupLife";
import _BaseBrick from "./types/_baseBrick";

enum Bricktypes {
  DEFAULT = 0,
  EXPLODE = 1,
  HEALTH = 2,
  BALL = 3
}

export default class Bricks extends Phaser.GameObjects.Group {
  particle!: Phaser.GameObjects.Particles.ParticleEmitterManager;
  particleEmitter!: Phaser.GameObjects.Particles.ParticleEmitter;

  constructor(scene: Phaser.Scene) {
    super(scene);

    /** Particles */
    this.particle = this.scene.add.particles('particle_explosion');
    this.particleEmitter = this.particle.createEmitter({
      speed: { min: -800, max: 800 },
      angle: { min: 0, max: 360 },
      scale: { start: 0.3, end: 0 },
      frequency: -1,
      quantity: 5,
      lifespan: 1500,
      gravityY: 800
    });

    /** Events */
    eventsController.on('bricks-explode', (data: any) => this.explode(data.x, data.y, data.direction));
    eventsController.on('brick-damaged', (data: any) => this.particleEmitter.explode(5, data.x, data.y));
    eventsController.on('brick-add', (data: any) => this.addBrick(data.x, data.y, data.texture, data.frame, data.type, data.properties));
    eventsController.on('brick-sort', () => this.sort());
  }

  addBrick(x: number, y: number, texture: string, frame: number, type: number, properties: any) {
    let brick: _BaseBrick;
    
    /** Brick: Create the correct brick based upon type */
    switch (type) {
      case Bricktypes.EXPLODE:
        brick = new BrickPowerupExplode(this.scene, x, y, texture, frame, properties.isExplodeHorizontal);
        break;
      case Bricktypes.HEALTH:
        brick = new BrickPowerupLife(this.scene, x, y, texture, frame);
        break;
      case Bricktypes.BALL:
        brick = new BrickPowerupBall(this.scene, x, y, texture, frame);
        break;
      case Bricktypes.DEFAULT:
        brick = new BrickDefault(this.scene, x, y, texture, frame, properties);
        break;
      default: 
        brick = new _BaseBrick(this.scene, x, y, texture, frame, properties);
        break;
    }

    /** Brick: Scale down in preparation for show animation */
    brick.setScale(0);

    /** Brick: Add single brick to group */
    this.add(brick);
  }

  hasActive() {
    return this.getFirstAlive() ? true : false;
  }

  sort() {
    this.getChildren().sort((a: any, b: any) => (a.y < b.y) ? 1 : -1);
  }

  explode(x: number, y: number, direction: string) {
    const allBricksInLine = direction === 'vertical' ? this.getMatching('x', x) : this.getMatching('y', y);
    allBricksInLine.forEach((brick: _BaseBrick) => {
      if (brick.active) brick.hit();
    });
  }

  show(callback: () => void) {
    this.scene.tweens.add({
      targets: this.getChildren(),
      scale: 1,
      duration: 250,
      ease: 'Back.Out',
      delay: this.scene.tweens.stagger(10, {}),
      onComplete: () => callback ? callback() : null
    })
  }
}