import Scaling from "./scaling";

export default class Constants {

    //Fonts
    static FONT_BOLD = "font_bold";
    static FONT_REGULAR = "font_regular";

    // Player //
    static PLAYER_LIVES = 3;

    // Game //
    static SCORE_PER_BRICK = 10;
    static SCORE_PER_LIFE = 300;
    static SCORE_PER_SECOND = 1;

    static BALL_SPEED = 400 * (Scaling.DPR * Scaling.GAME_BASE_DIFF_HEIGHT);
    static BALL_SPEED_INCREMENT = 0;
}