import eventsController from "@/game/breakout/controllers/eventsController";
import _BaseBrick from "./_baseBrick";

export default class BrickPowerupExplode extends _BaseBrick {
  direction: string;

  constructor(scene: Phaser.Scene, x: number, y: number, texture: string, frame: number, isExplodeHorizontal: boolean) {
    super(scene, x, y, texture, frame);

    // Settings //
    this.direction = isExplodeHorizontal ? 'horizontal' : 'vertical';
    this.setName('powerupExplode');
  }

  hit() {
    super.hit();
    eventsController.emit('bricks-explode', { x: this.x, y: this.y, direction: this.direction });
  }
}