import Phaser from "phaser";
import WebFont from "webfontloader";
import Constants from "../configs/constants";
import Scaling from "../configs/scaling";

export default class Boot extends Phaser.Scene {

  constructor() {
    super({ key: "boot" });
  }

  preload() {
    // Load Fonts ///
    this.load.on("complete", () => {
      WebFont.load({
        custom: {
          families: [Constants.FONT_REGULAR, Constants.FONT_BOLD]
        },
        active: () => this.booted(true),
        inactive: () => this.booted(false)
      });
    });

    // Load images //
    this.load.image('campaign_logo', Scaling.imagePath('logo_campaign', 'png'));
    this.load.image('game_background', Scaling.imagePath('game-background', 'png'));
  }

  booted(fonts: boolean) {
    // Fonts: Loaded check //
    if (!fonts) { console.error("fonts failed to load...") }

    // Scene: launch parallel background scene //
    this.scene.launch('background');
    this.scene.stop();
  }
}
