import Phaser from "phaser";
import Scaling from "../configs/scaling";
import CircularProgress from 'phaser3-rex-plugins/plugins/circularprogress';
import Setup from "@/helpers/Setup";
import Background from "./background";

enum LoadStates {
  LOADING = 'loading',
  FINISH_STORY = 'story',
  FINISH_LOAD = 'load'
}

export default class Load extends Phaser.Scene {
  logo!: Phaser.GameObjects.Image;
  loader!: CircularProgress;
  loadProgressRequired: LoadStates[] = [LoadStates.FINISH_LOAD];
  loadProgress: LoadStates[] = [];

  constructor() {
    super({ key: "load" });
  }

  preload() {
    /** Logo */
    this.logo = this.add.image(this.cameras.main.width / 2, (this.cameras.main.height / 2) - (Scaling.getPixelbyDPR(60)), 'campaign_logo');

    /** Progression */
    this.loader = new CircularProgress(this, {
      x: this.cameras.main.centerX, y: this.cameras.main.height - Scaling.getPixelbyDPR(80),
      radius: Scaling.getPixelbyDPR(25),
      trackColor: 0x000000,
      barColor: 0xFFFFFF,
      thickness: 0.1,
      valuechangeCallback: () => { }
    });
    this.add.existing(this.loader);

    /** Loading: Levels */
    const levelsJson = JSON.parse(Setup.getValue('settings.game.levels.json').value);
    levelsJson.levels.forEach((level: any, index: number) => {
      this.load.tilemapTiledJSON(`level${index + 1}`, level);  
    });

    /** Loading: Level tileset */
    const tilesetImage = Setup.getValue('settings.game.levels.tileset').find((tileset:any) => tileset.fileName.includes(`@${Scaling.DPR}x`));
    this.load.spritesheet('tiles', tilesetImage.url, { frameWidth: 20 * Scaling.DPR, frameHeight: 20 * Scaling.DPR });

    /** Loading: Images */
    this.load.image("icon_stopwatch", Scaling.imagePath("icon-timer", "png"));
    this.load.image("icon_lives", Scaling.imagePath("icon-lifes", "png"));
    this.load.image("icon_coin", Scaling.imagePath("icon-points", "png"));

    this.load.image("sprite_paddle", Scaling.imagePath("player", "png"));
    this.load.image("sprite_ball", Scaling.imagePath("bullet", "png"));
    this.load.image("particle_explosion", Scaling.imagePath("sprite-target-particles", "png"));

    /** Loading: Audio */
    this.load.audio("theme", Setup.getSound("background.mp3"));
    this.load.audio("button-confirm", Setup.getSound("button-default.mp3"));
    this.load.audio("bounce", Setup.getSound("bounce.mp3"));
    this.load.audio("hit", Setup.getSound("hit.mp3"));
    this.load.audio("launch", Setup.getSound("launch.mp3"));

    /** Progression: onProgress */
    this.load.on("progress", (value: number) => {
      this.loader.setValue(value);
    });

    /** Progression: onComplete */
    this.load.on("complete", () => {
      this.loader.setAlpha(0);

      this.finish(LoadStates.FINISH_LOAD);
      this.tweens.add({
        targets: this.logo,
        alpha: 0,
        duration: 500,
        ease: 'ease'
      })

      const backgroundScene = this.scene.get("background") as Background;
      backgroundScene.playAudio();
    });    
  }

  finish(state: LoadStates) {
    this.loadProgress.push(state);

    const isCompleted = this.loadProgressRequired.every(progress => this.loadProgress.includes(progress));
    if (isCompleted) {
      this.time.delayedCall(2500, () => this.game.events.emit("loaded"));
    }
  }  
}


