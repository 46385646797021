import eventsController from "@/game/breakout/controllers/eventsController";
import Phaser from "phaser";
import Constants from "../../../configs/constants";
import BallTrail from "../helpers/ballTrail";

export default class _BaseBall extends Phaser.Physics.Arcade.Sprite {
  trail: BallTrail;
  speed: number;
  speedModifier: number;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, 'sprite_ball');
    this.scene.add.existing(this);
    this.scene.physics.add.existing(this);

    // Settings //
    this.trail = new BallTrail(this.scene, this);
    this.speed = Constants.BALL_SPEED;
    this.speedModifier = Constants.BALL_SPEED_INCREMENT;

    // Physics //
    this.setCollideWorldBounds(true);
    this.setBounce(1);

    // State //
    this.setActive(false).setVisible(false);
  }

  kill() {
    this.setActive(false).setVisible(false);
    this.trail.kill();
  }

  revive(x: number, y: number, isStatic: boolean) {
    this.setPosition(x, y);
    this.setActive(true).setVisible(true);

    this.trail.reset();

    if (!isStatic) {
      this.setSpeed(this.speed, Phaser.Math.RND.between(-400, 400));
    } else {
      this.setVelocity(0, 0);
    }
  }

  setSpeed(speedY: number, speedX?: number) {
    this.speed = speedY;

    this.setVelocityY(this.body.velocity.y > 0 ? this.speed : -this.speed)
    speedX ? this.setVelocityX(speedX) : null;
  }

  increaseSpeed() {
    this.setSpeed(this.speed + this.speedModifier);
  }

  preUpdate(time: number, delta: number) {
    super.preUpdate(time, delta);

    if (this.active) {
      this.trail.update();

      if (this.y > this.scene.cameras.main.height) {
        this.kill();
        eventsController.emit('ball-death');
      }
    }
  }
}