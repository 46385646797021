import Setup from "@/helpers/Setup";
import { ITrackingData } from "../interfaces/core/ITrackingData";

const normalizeValue = function (value: number, min: number, max: number) {
    return (value - min) / (max - min);
};

const linearInterpolation = function (norm: number, min: number, max: number) {
    return (max - min) * norm + min;
};

const remapValue = function (value: number, low1: number, high1: number, low2: number, high2: number) {
    return low2 + (value - low1) * (high2 - low2) / (high1 - low1);
}

const sendTracking = function (event: ITrackingData) {
    const root = (window as any);
    root.dataLayer = root.dataLayer || [];
    root.dataLayer.push(event);
}

const arrayEquals = function (a: any[], b: any[]) {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
}

const getCenterBetweenSprites = function (sprite1: any, sprite2: any) {
    const isLeftToRight = sprite1.getBounds().right < sprite2.getBounds().left ? true : false;
    if (isLeftToRight) {
        return sprite1.getBounds().right + ((sprite2.getBounds().left - sprite1.getBounds().right) / 2);
    } else {
        return sprite2.getBounds().right + ((sprite1.getBounds().left - sprite2.getBounds().right) / 2);
    }
}

const randomIntBetween = function (min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

const millisToMinutesAndSeconds = function (millis: number) {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return (minutes < 10 ? '0' : '') + minutes + ":" + (parseInt(seconds) < 10 ? '0' : '') + seconds;
}

const getCapeColorAsInterger = function (key: string): number {
    const color = Setup.getValue(key);
    const colorFormatted = color ? color.substring(0, 7) : '#FFFFFF';

    return Phaser.Display.Color.HexStringToColor(colorFormatted).color;
}

export {
    normalizeValue,
    sendTracking,
    linearInterpolation,
    remapValue,
    arrayEquals,
    getCenterBetweenSprites,
    randomIntBetween,
    millisToMinutesAndSeconds,
    getCapeColorAsInterger
}