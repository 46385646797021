import eventsController from "@/game/breakout/controllers/eventsController";
import _BaseBrick from "./_baseBrick";

export default class BrickPowerupLife extends _BaseBrick {
  constructor(scene: Phaser.Scene, x: number, y: number, texture: string, frame: number) {
    super(scene, x, y, texture, frame);

    // Settings //
    this.setName('powerupLife');
  }

  hit() {
    super.hit();
    eventsController.emit('life-gain');
  }
}