import Scaling from "../../configs/scaling";
import _BaseBrick from "../bricks/types/_baseBrick";
import eventsController from "../../controllers/eventsController";
import { IIndexable } from "../../interfaces/core/IDefault";

interface ILevelSettings {
  key: string;
}

export default class Level {
  scene: Phaser.Scene;
  map!: Phaser.Tilemaps.Tilemap;

  constructor(scene: Phaser.Scene, settings: ILevelSettings) {
    this.scene = scene;

    const tilemap = this.createTilemap(settings.key);
    const tileset = this.createTileset(tilemap);
    const tilemapLayer = this.createLayer(tilemap, tileset);
    this.createBricks(tilemap, tileset);

    eventsController.emit('brick-sort');
  }

  createTilemap(level: string) {
    return this.scene.make.tilemap({ key: level });
  }

  createTileset(tilemap: Phaser.Tilemaps.Tilemap) {
    return tilemap.addTilesetImage('bricks', 'tiles');
  }

  createLayer(tilemap: Phaser.Tilemaps.Tilemap, tileset: Phaser.Tilemaps.Tileset) {
    const layerPosition = {
      x: this.scene.cameras.main.centerX - Scaling.getPixelbyDPR(tilemap.widthInPixels / 2),
      y: this.scene.cameras.main.centerY - Scaling.getPixelbyDPR(tilemap.heightInPixels / 2) - Scaling.getPixelbyDPR(40)
    }
    const layer = tilemap.createLayer('level', tileset, layerPosition.x, layerPosition.y);
    layer.setScale(Scaling.DPR);
  }

  createBricks(tilemap: Phaser.Tilemaps.Tilemap, tileset: Phaser.Tilemaps.Tileset) {
    let bricks = [] as _BaseBrick[];

    Object.values(tileset.image.frames).forEach(type => {
      tilemap.createFromTiles((type.name + 1), 0, { key: 'tiles' }).map(sprite => {

        const props = (tileset.tileProperties as IIndexable)[type.name];

        eventsController.emit('brick-add', {
          x: sprite.x,
          y: sprite.y,
          texture: sprite.texture.key,
          frame: type.name,
          type: props?.powerup_type || 0,
          properties: props
        })

        sprite.destroy();
      })
    })

    return bricks;
  }
}