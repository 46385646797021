import Phaser from "phaser";
import Scaling from "../../configs/scaling";
import eventsController from "../../controllers/eventsController";
import _BaseBall from "../balls/types/_baseBall";

export default class Player extends Phaser.Physics.Arcade.Sprite {
  attachedBall: _BaseBall | null;
  canLaunch: boolean;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y, 'sprite_paddle');
    this.scene.add.existing(this);
    this.scene.physics.add.existing(this);

    // References //
    this.attachedBall = null;

    // Settings //
    this.canLaunch = false;

    // Physics //
    this.setSize(this.width + Scaling.getPixelbyDPR(10), this.height);
    this.setImmovable(true);

    // Controls //
    this.scene.input.on('pointermove', (pointer: Phaser.Input.Pointer) => this.move(pointer.x));
    this.scene.input.on('pointerup', () => this.launch());
  }

  move(x: number) {
    this.x = Phaser.Math.Clamp(x, this.width / 2, this.scene.cameras.main.width - (this.width / 2));

    if (this.attachedBall) {
      this.attachedBall.x = this.x;
    }
  }

  attachBall() {
    eventsController.emit('balls-spawn', { x: this.x, y: this.y, isStatic: true }, (ball: any) => {
      ball.x = this.getTopCenter().x;
      ball.y = this.getTopCenter().y - Scaling.getPixelbyDPR(24);

      this.attachedBall = ball;
    });
  }

  setLaunchable(value: boolean) {
    this.canLaunch = value;
  }

  bounce(ball: _BaseBall) {
    let diff = 0;

    //  Ball is on the left-hand side of the paddle
    if (ball.x < this.x) {
      diff = this.x - ball.x;
      ball.setVelocityX(-10 * diff);
    }

    //  Ball is on the right-hand side of the paddle
    else if (ball.x > this.x) {
      diff = ball.x - this.x;
      ball.setVelocityX(10 * diff);
    }

    //  Ball is perfectly in the middle
    else {
      ball.setVelocityX(2 + Math.random() * 8);
    }

    eventsController.emit('sound-play', 'bounce');
  }

  launch() {
    if (this.attachedBall && this.canLaunch) {
      this.attachedBall.setSpeed(this.attachedBall.speed, 1);
      this.attachedBall = null;
      
      eventsController.emit('sound-play', 'launch');
      eventsController.emit('game-start');
    }
  }
}