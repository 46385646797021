import Constants from "@/game/breakout/configs/constants";
import eventsController from "@/game/breakout/controllers/eventsController";
import sessionSaveFile from "@/utils/game/SessionSaveFile";
import Phaser from "phaser";

export default class _BaseBrick extends Phaser.Physics.Arcade.Sprite {
  fortifiedDamageTexture!: string | number;
  invulnerable: boolean = false;
  fortified: boolean = false;
  frameDamaged: number;

  constructor(scene: Phaser.Scene, x: number, y: number, texture: string, frame: number, settings?: any) {
    super(scene, x, y, texture, frame);
    this.scene.add.existing(this);
    this.scene.physics.add.existing(this);

    /** Settings */
    this.fortified = settings?.fortified;
    this.frameDamaged = settings?.damaged_id;

    this.setName('default');

    /** Physics */
    this.setImmovable(true);
    this.setCollisionState(true);
  }

  canCollide(): boolean {
    return this.body.enable;
  }

  setCollisionState(state: boolean) {
    this.body.enable = state;
  }

  isFortified(){
    return this.fortified;
  }

  setFortified(state: boolean){
    this.fortified = state;
  }

  hit() {
    if (this.isFortified()) {
      this.setFortified(false);
      this.setFrame(this.frameDamaged);
    } else {
      this.setActive(false).setVisible(false);
      this.setCollisionState(false);
      eventsController.emit('score-update', { amount: Constants.SCORE_PER_BRICK });
      sessionSaveFile.incrementValue('bricks', 1);
    }
    
    this.scene.cameras.main.shake(200, 0.005);
    
    eventsController.emit('brick-damaged', { x: this.x, y: this.y });
    eventsController.emit('sound-play', 'bounce');
    eventsController.emit('sound-play', 'hit');
  }

}