import Phaser from "phaser";
import {getCapeColorAsInterger, linearInterpolation} from "../../../configs/helpers";
import _BaseBall from "../types/_baseBall";
import Scaling from "@/game/breakout/configs/scaling";

export default class BallTrail extends Phaser.GameObjects.Graphics {
  ball: _BaseBall;
  points: any[];
  head: any;
  lifespan: number;
  color: number;

  constructor(scene: Phaser.Scene, ball: _BaseBall, options?: any) {
    super(scene, options);
    this.scene.add.existing(this);

    // References //
    this.ball = ball;

    // Settings //
    this.points = [];
    this.head = { x: 0, y: 0 };
    this.lifespan = 5;
    this.color = getCapeColorAsInterger('settings.game.general.ballColor');
  }

  kill() {
    this.setActive(false).setVisible(false);
  }

  reset() {
    this.points = [];
    this.setActive(true).setVisible(true);
  }

  update() {
    this.clear();

    // Trail: add new position to trail, if ball is moving //
    if (this.ball.x !== this.head.x && this.ball.y !== this.head.y) {
      this.head.x = this.ball.x;
      this.head.y = this.ball.y;
      this.points.push({
        x: this.head.x,
        y: this.head.y,
        lifespan: this.lifespan
      });
    }

    // Trail: If the ball is moving, render trail //
    if (this.points.length > 4) {
      this.lineStyle(1, this.color, 1.0);
      this.beginPath();
      this.lineStyle(0, this.color, 1.0);
      this.moveTo(this.points[0].x, this.points[0].y);
      for (var index = 1; index < this.points.length - 4; ++index) {
        var point = this.points[index];
        this.lineStyle(linearInterpolation(index / (this.points.length - 4), 0, Scaling.getPixelbyDPR(10)), this.color, 0.5);
        this.lineTo(point.x, point.y);
      }
      var count = 0;
      for (var index = this.points.length - 4; index < this.points.length; ++index) {
        var point = this.points[index];
        this.lineStyle(linearInterpolation(count++ / 4, Scaling.getPixelbyDPR(10), 0), this.color, 1.0);
        this.lineTo(point.x, point.y);
      }
      this.strokePath();
      this.closePath();
    }

    // Trail: Remove points when their lifespan is gone //
    for (var index = 0; index < this.points.length; ++index) {
      var point = this.points[index];
      point.lifespan -= 0.5;
      if (point.lifespan <= 0) {
        this.points.splice(index, 1);
        index -= 1;
      }
    }
  }
}